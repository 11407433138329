<template>
    <div class="total">
        <div class="title_page">
            <div class="sig"></div>
            <div class="page_name">入校统计图</div>
        </div>
        <!-- 统计图部分 -->
        <div class="statistics">
            <!-- 左边环形统计图部分 -->
            <div class="left_date">
                <!--下拉框  日期选择部分  -->
                <div class="date_selet">
                    <el-select v-model="school_id" placeholder="校区" clearable size="mini" @change="handle_school">
                        <el-option v-for="item in school_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                    <el-select v-model="class_id" placeholder="班级" clearable size="mini" style="margin-left:16px"
                        @change="total">
                        <el-option v-for="item in class_arr" :key="item.uid" :label="item.name" :value="item.uid">
                        </el-option>
                    </el-select>
                    <div class="date_div">
                        <el-date-picker v-model="total_start" type="date" placeholder="开始日期" size="mini"
                            format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                        </el-date-picker>
                        <span>~</span>
                        <el-date-picker v-model="total_end" type="date" placeholder="结束日期" size="mini"
                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="total">
                        </el-date-picker>
                    </div>
                </div>
                <!-- 环形图显示部分 -->
                <div class="annular" ref="an_charts"></div>
            </div>
            <!-- 右边折线图部分 -->
            <div class="broken" ref="broken"></div>
        </div>
        <!-- 选项卡列表部分 -->
        <div class="tab_list">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="打卡列表" name="first">
                    <!-- 表格内容 -->
                    <div class="avatar_table">
                        <el-card>
                            <!-- 导出 下拉框 按钮部分 -->
                            <div class="out_sel_btn">
                                <div class="totle_div">
                                    <div class="sel_inp">
                                        <el-select v-model="am_campus_id" placeholder="校区" clearable size="mini"
                                            @change="initData">
                                            <el-option v-for="item in am_campus_arr" :key="item.uid" :label="item.name"
                                                :value="item.uid">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="time_date">
                                        <el-date-picker v-model="am_start" type="date" placeholder="开始日期" size="mini"
                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                        <span>~</span>
                                        <el-date-picker v-model="am_end" type="date" placeholder="结束日期" size="mini"
                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="initData">
                                        </el-date-picker>
                                    </div>
                                </div>
                                <div class="add_del_btn">
                                    <el-button class="addBtn" type="primary" @click="handle_Amout">导出</el-button>
                                </div>
                            </div>
                            <!-- 内容表格部分 -->
                            <div class="table_init">
                                <el-table :data="Admission_tableData" tooltip-effect="dark" style="width: 100%"
                                    :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                                    <el-table-column prop="uid" label="ID">
                                    </el-table-column>
                                    <el-table-column prop="campus_uid" label="校区">
                                    </el-table-column>
                                    <el-table-column prop="student_uid" label="班级">
                                    </el-table-column>
                                    <el-table-column prop="name" label="学生">
                                    </el-table-column>
                                    <el-table-column prop="teacher_tel" label="确认老师">
                                    </el-table-column>
                                    <el-table-column prop="in_date" label="入校时间">
                                    </el-table-column>
                                    <el-table-column prop="leave_date" label="离校时间">
                                    </el-table-column>
                                </el-table>
                            </div>
                            <!-- 分页部分 -->
                            <Paging :count="am_count" :initData="initData" @changePage="handle_changePage"></Paging>
                        </el-card>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="请假列表" name="second">
                    <!-- 表格内容 -->
                    <div class="avatar_table">
                        <el-card>
                            <!-- 导出 下拉框 按钮部分 -->
                            <div class="out_sel_btn">
                                <div class="totle_div">
                                    <div class="sel_inp">
                                        <el-select v-model="le_campus_id" placeholder="校区" clearable size="mini"
                                            @change="initData1">
                                            <el-option v-for="item in le_campus_arr" :key="item.uid" :label="item.name"
                                                :value="item.uid">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="time_date">
                                        <el-date-picker v-model="le_start" type="date" placeholder="开始日期" size="mini"
                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                        <span>~</span>
                                        <el-date-picker v-model="le_end" type="date" placeholder="结束日期" size="mini"
                                            format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="initData1">
                                        </el-date-picker>
                                    </div>
                                </div>
                                <div class="add_del_btn">
                                    <el-button class="addBtn" type="primary" @click="handle_Leout">导出</el-button>
                                </div>
                            </div>
                            <!-- 内容表格部分 -->
                            <div class="table_init">
                                <el-table :data="leave_tableData" tooltip-effect="dark" style="width: 100%"
                                    :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                                    <el-table-column prop="uid" label="ID">
                                    </el-table-column>
                                    <el-table-column prop="campus_uid" label="校区">
                                    </el-table-column>
                                    <el-table-column prop="student_uid" label="班级">
                                    </el-table-column>
                                    <el-table-column prop="partner_uid" label="家长">
                                    </el-table-column>
                                    <el-table-column prop="name" label="学生">
                                    </el-table-column>
                                    <el-table-column prop="teacher_tel" label="确认老师">
                                    </el-table-column>
                                    <el-table-column prop="reason_type" label="请假类型">
                                    </el-table-column>
                                    <el-table-column prop="created_at" label="请假时间">
                                    </el-table-column>
                                </el-table>
                            </div>
                            <!-- 分页部分 -->
                            <Paging :count="le_count" :initData="initData1" @changePage="handle_changePage"></Paging>
                        </el-card>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import Paging from '@/components/Paging';
import { getadmissApi, showadmissApi, seladmissApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 登录用户id
            user_id: '',
            // 选项卡
            activeName: 'first',
            // 统计图
            school_id: '',   //校区
            school_arr: [],
            class_id: '',
            class_arr: [],   //班级
            total_start: '',  //开始时间
            total_end: '',    //结束时间
            school_num: 0,   //总人数
            admiss: 0,   //入校人数 
            duty: 0,    //缺勤人数
            leave: 0,   //请假人数
            x_arr: [],  //x轴数据
            y_max: 0,   //y轴最大值
            y_step: 0,    //y轴步进
            one_count: [],   //请假数据
            two_count: [],    //缺勤数据
            //   入校****************************************
            Admission_tableData: [],   //表格数据
            am_campus_id: '',   //校区id
            am_campus_arr: [],
            am_start: '',    //开始时间
            am_end: '',    //结束时间
            // 分页
            am_page: 1,
            am_count: 0,
            am_max: 10,
            //    请假****************************************
            leave_tableData: [],   //表格数据
            le_campus_id: '',   //校区id
            le_campus_arr: [],
            le_start: '',   //开始时间
            le_end: '',     //结束时间
            // 分页
            le_page: 1,
            le_count: 0,
            le_max: 10,
        }
    },
    mounted () {
        this.total()
    },
    created () {
        this.initData();
    },
    watch: {
        // 监听分页数量的变化  打卡
        am_count () {
            if (this.am_count == (this.am_page - 1) * this.am_max && this.am_count != 0) {
                this.am_page -= 1;
                this.initData()
            }
        },
        // 监听分页数量的变化  请假
        le_count () {
            if (this.le_count == (this.le_page - 1) * this.le_max && this.le_count != 0) {
                this.le_page -= 1;
                this.initData1()
            }
        },
    },
    methods: {
        // 选项卡点击
        handleClick () {
            if (this.activeName == 'first') {
                this.initData()
            } else {
                this.initData1()
            }
        },
        // 子组件传过来的当前页数
        handle_changePage (data) {
            if (this.activeName == 'first') {
                this.am_page = data;
            } else {
                this.le_page = data;
            };
        },
        // 打卡初始化
        initData () {
            this.user_id = sessionStorage.getItem('id');
            getadmissApi({
                page: this.am_page,   //当前页
                type: 1,   //数据来源
                campus_uid: this.am_campus_id,     //校区id
                auth_userid: this.user_id,  //登录用户id
                start_date: this.am_start,   //开始日期
                end_date: this.am_end,    //结束日期
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.Admission_tableData = res.data;
                    this.am_count = res.count;
                    this.am_campus_arr = res.campus;  //校区
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 请假初始化
        initData1 () {
            this.user_id = sessionStorage.getItem('id');
            getadmissApi({
                page: this.le_page,   //当前页
                type: 2,   //数据来源
                campus_uid: this.le_campus_id,     //校区id
                auth_userid: this.user_id,  //登录用户id
                start_date: this.le_start,   //开始日期
                end_date: this.le_end,    //结束日期
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.leave_tableData = res.data;
                    this.le_count = res.count;
                    this.le_campus_arr = res.campus;  //校区
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 统计图获取数据
        total () {
            this.user_id = sessionStorage.getItem('id');
            showadmissApi({
                class_uid: this.class_id,   //班级id
                campus_uid: this.school_id,     //校区id
                auth_userid: this.user_id,  //登录用户id
                start_date: this.total_start,   //开始日期
                end_date: this.total_end,    //结束日期
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.school_arr = res.campus;   //校区
                    this.school_num = res.count;   //总人数
                    this.admiss = res.is_in;   //入校人数 
                    this.duty = res.no_in;   //缺勤人数
                    this.leave = res.leave;  //请假人数
                    this.x_arr = res.view.data;  //x轴数据
                    this.y_max = res.view.max;  //y轴最大值
                    this.y_step = res.view.step;  //y轴步进
                    this.one_count = res.view.leave_count;   //请假数据
                    this.two_count = res.view.no_data_count;  //缺勤数据
                    this.init_annular();
                    this.init_broken();
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 统计图下拉框改变
        handle_school () {
            this.total();
            if (this.school_id == '') {
                this.class_id = '';
                this.class_arr = [];
            } else {
                this.class_id = '';
                this.class_arr = [];
                seladmissApi({
                    campus_uid: this.school_id,     //校区id
                    auth_userid: this.user_id,  //登录用户id
                }).then(res => {
                    // console.log(res)
                    if (res.status.code == 200) {
                        this.class_arr = res.class;   //班级
                    } else if (res.status.code == 201) {
                        this.$message.error(res.status.msg)
                    } else if (res.status.code == 202) {
                        this.$message.error('登录过期，请重新登录！');
                        this.$router.push({ path: '/login' })
                    }
                })
            }
        },
        // 环形统计图数据
        init_annular () {
            let annular_chart = this.$echarts.init(this.$refs.an_charts);
            // 绘制图表
            annular_chart.setOption({
                title: {
                    text: '总人数',
                    subtext: this.school_num,
                    subtextStyle: {
                        color: '#333333',

                    },
                    left: 'center',
                    top: '40%'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: '30%',
                    left: 'right',   //图例组件离容器左侧的距离
                },
                series: [
                    {
                        type: 'pie',   //形状为饼图
                        radius: ["50%", "70%"], // 这个属性修改圆环宽度
                        avoidLabelOverlap: false, //是否启用防止标签重叠策略
                        label: {   //饼图图形上的文本标签
                            show: false,
                            position: 'center'
                        },
                        emphasis: {  //文本标签在中间是否显示 以及显示样式
                            label: {
                                show: false,
                                // fontSize: '16',
                                // fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: this.admiss, name: '入校', itemStyle: { color: '#5ec9db' } },
                            { value: this.duty, name: '缺勤', itemStyle: { color: '#f27d51' } },
                            { value: this.leave, name: '请假', itemStyle: { color: '#fdc765' } },
                        ]
                    }
                ]
            })
        },
        // 折线图
        init_broken () {
            let broken_chart = this.$echarts.init(this.$refs.broken);
            broken_chart.setOption({
                tooltip: {
                    trigger: 'axis'
                },
                color: ['#519cf4', '#ff635a'],
                legend: {
                    left: '10%',
                    top: '5%',
                    // orient: 'vertical',
                    data: ['请假', '缺勤']
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '5%',
                    containLabel: true,
                    width: '95%'
                },
                xAxis: {
                    type: 'category',
                    // boundaryGap: true,
                    boundaryGap: ['5%', '5%'],
                    data: this.x_arr,
                    axisTick: { //关键设置，坐标轴刻度也不显示
                        show: false,
                    },
                    splitLine: {  //去掉背景网格线
                        show: false,
                    },
                },
                yAxis: {
                    type: 'value',
                    min: 0,
                    max: this.y_max,
                    interval: this.y_step,
                    axisTick: { //关键设置，坐标轴刻度也不显示
                        show: false,
                    },
                    splitLine: {  //去掉背景网格线
                        show: false,
                    },
                },
                series: [
                    {
                        name: '请假',
                        type: 'line',
                        smooth: true,
                        data: this.one_count
                    },
                    {
                        name: '缺勤',
                        type: 'line',
                        smooth: true,
                        data: this.two_count
                    }
                ]
            })
        },
        // 导出数据   打卡
        handle_Amout () {
            window.location.href = "https://education.ztxinde.com/sch_s_export?page=" + this.am_page + '&campus_uid=' + this.am_campus_id + '&auth_userid=' + this.user_id + '&type=1' + '&start_date=' + this.am_start + '&end_date=' + this.am_end
        },
        // 导出数据   请假
        handle_Leout () {
            window.location.href = "https://education.ztxinde.com/sch_s_export?page=" + this.le_page + '&campus_uid=' + this.le_campus_id + '&auth_userid=' + this.user_id + '&type=2' + '&start_date=' + this.le_start + '&end_date=' + this.le_end
        },
    },
}
</script>

<style lang="scss" scoped>
.total {
    width: 100%;
    height: 100%;
    // 页面标题部分
    .title_page {
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .sig {
            width: 18px;
            height: 4px;
            margin-right: 8px;
            background: #2479dd;
        }
        .page_name {
            font-size: 18px;
            font-weight: 600;
        }
    }
    // 统计图部分
    .statistics {
        width: 100%;
        height: 315px;
        border-radius: 12px;
        background: #ffffff;
        display: flex;
        // 左边环形统计图部分
        .left_date {
            width: 50%;
            height: 100%;
            padding: 20px 0 0 20px;
            text-align: left;
            // 下拉框  日期部分
            .date_selet {
                display: flex;
                margin-bottom: 26px;
                // 下拉框
                .el-select {
                    width: 140px;
                    height: 30px;
                }
                // 日期
                .date_div {
                    display: flex;
                    margin-left: 16px;
                    span {
                        margin: 5px 6px;
                    }
                    .el-input {
                        width: 150px;
                    }
                    ::v-deep.el-input__inner {
                        width: 150px;
                    }
                }
            }
            // 环形统计图部分
            .annular {
                width: 100%;
                height: 250px;
                position: relative;
                left: -166px;
                top: 0;
            }
        }
        // 右边折线图部分
        .broken {
            width: 50%;
            height: 100%;
            position: relative;
            left: -10px;
            top: 0;
        }
    }
    // 选项卡
    .tab_list {
        margin-top: 32px;
        .el-tabs {
            ::v-deep.el-tabs__active-bar {
                display: none;
            }
            ::v-deep.el-tabs__item {
                width: 112px;
                height: 40px;
                text-align: center;
                color: #525252;
                font-size: 18px;
                font-weight: 900;
            }
            // ::v-deep.el-tabs__item:hover {
            //     color: #333333;
            //     font-size: 18px;
            //     font-weight: 900;
            // }
            ::v-deep.el-tabs__item.is-active {
                width: 112px;
                height: 40px;
                // text-align: center;
                border-radius: 24px;
                background: #2479dd;
                color: #ffffff;
                font-weight: 500;
            }
            ::v-deep#tab-first {
                text-align: right;
            }
            ::v-deep#tab-second {
                text-align: left;
            }
            ::v-deep.el-tabs__nav-wrap::after {
                position: static !important;
            }
            // 表格显示内容
            .avatar_table {
                margin-bottom: 20px;
                // 下拉框  添加  删除按钮部分
                .out_sel_btn {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .totle_div {
                        display: flex;
                        .sel_inp {
                            margin-right: 16px;
                            .el-select {
                                width: 150px;
                                height: 30px;
                            }
                        }
                        .time_date {
                            span {
                                margin: 5px 6px;
                            }
                            .el-input {
                                width: 150px;
                            }
                            ::v-deep.el-input__inner {
                                width: 150px;
                            }
                        }
                    }
                    // 添加  删除按钮部分
                    .add_del_btn {
                        text-align: right;
                        .el-button {
                            width: 80px;
                            height: 30px;
                            line-height: 4px;
                        }
                    }
                }
                // 表格内容
                .table_init {
                    margin-top: 20px;
                    text-align: center;
                    // 操作按钮
                    span {
                        border: none;
                        width: 24px;
                        height: 18px;
                        font-size: 12px;
                        font-weight: 400;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>